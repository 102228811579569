.chat-support-container {
    position:fixed;
    bottom:25px;
    right:80px;
}

.chat-support-container-opened {
    position:fixed;
    bottom:0;
    right:0;
    width:100%;
    height:100vh;
    max-width:500px;
    background-color: #e7e7e7;
    border: 1px solid #dddddd;
}

.chat-support-container {
    display: block;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255 255 255 / 0%);
}

div.adm-sup-body {
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 20px;
    padding-bottom: 240px;
    position: fixed;
    top:179px;
  }

div.chat-support-msg-body {
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 20px;
    padding-bottom: 240px;
    position: fixed;
    top:102px;
  }
.close-btn{
    position: fixed;
    top:88px;
}
.chats-support-contacts{
    border-bottom: 1px solid #ccc;
    width: 100%;
    background-color:#e7e7e7;
}
.user-contact{
    display:inline-flex;
    margin-left:20px;
    border-radius:10px;
    background-color:#f0f0f0;
    padding:5px;
}
.admin-container{
    padding:70px 0px 0px
}
  
.input-fixed{
    position: fixed;
    bottom:0;
    background-color: #fff;
    padding-bottom: 10px;
}
.user-input-fixed{
        position: fixed;
        bottom:0;
        width:100%;
    }
@media screen and (min-width: 540px) {
    .chat-support-container-opened {
        position:fixed;
        bottom:40px;
        right:40px;
        width:100%;
        height:500px;
        max-width:500px;
        background-color: #e7e7e7;
        border: 1px solid #dddddd;
    }
    .close-btn{
        position: absolute;
        top:0;
    }
    .chat-support-container{
        flex-direction: column;
    }
    div.adm-sup-body {
        height: 370px;
        padding-bottom: 60px;
        position: unset;
      }
      
    div.chat-support-msg-body {
        height: 430px;
        padding-bottom: 0px;
        padding-top: 32px;
        position: unset;
      }
    .admin-container{
        padding:0px
    }
    .input-fixed{
        position: absolute;
        bottom:0;
        padding-bottom: 0px;
    }
    .user-input-fixed{
        bottom:40px;
        width:500px;
    }
    
}